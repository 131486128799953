// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

$primary: rgb(164, 27, 33);
$primary-alt: rgb(164, 27, 33);

$theme-colors: (
    "red-light-best": rgb(237, 28, 36),
    "red-dark-best": rgb(164, 27, 33),
    "gray-light-best": rgb(143, 145, 148),
    "gray-dark-best": rgb(87, 87, 89),
);
